<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.veiculo.abas.dados_veiculo') }}
      </v-tab>
      <v-tab v-if="visualizar">
        {{ $t('modulos.veiculo.abas.historico') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-veiculo
          ref="dados-veiculo" 
          :form="form" 
        />
      </v-tab-item>
      <v-tab-item>
        <Historico :id="id" />
      </v-tab-item>
    </v-tabs-items>
    <v-divider  
      class="my-5"
    />
    <div 
      class="d-flex align-center justify-end"
    >
      <botao-padrao
        outlined
        color="secondary" 
        class="mr-2" 
        @click="cancelar"
      >
        <v-icon>{{visualizar ? '$mdiArrowLeft' : '$mdiCancel'}}</v-icon>
        {{ visualizar ? $t('geral.botoes.voltar') : $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao 
        v-if="!visualizar" 
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import VeiculoService from '@common/services/cadastros/VeiculoService';
import { VeiculoModel } from '@common/models/cadastros/VeiculoModel';
import helpers from '@common/utils/helpers';
import DadosVeiculo from './DadosVeiculo.vue';
import Historico from './Historico.vue';
export default {
  components: {
    DadosVeiculo,
    Historico,
  },
  props: ['id'],
  data() {
    return {
      visualizar: false,
      valido: false,
      form: new VeiculoModel({}),
      indiceAba: 0,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.visualizar) return this.$t('modulos.veiculo.titulos.visualizar');
      if (this.id) return this.$t('modulos.veiculo.titulos.editar');
      return this.$t('modulos.veiculo.titulos.novo');
    },
  },
  mounted() {
    if (this.$route.name == 'veiculo-visualizar') {
      this.visualizar = true;
    }
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Veiculo', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Veiculo', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      VeiculoService.buscar(this.id)
        .then((res) => {
          this.form = new VeiculoModel(res.data);
          this.valido = true;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.$refs['dados-veiculo']?.$refs.form)
        this.valido = this.$refs['dados-veiculo']?.$refs.form.validate();
      if (!this.valido) return;
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      VeiculoService.salvar(this.form.request)
        .then(() => {
          this.$router.push({ name: 'veiculo' });
          this.toastSucesso(this.$t(`modulos.veiculo.cadastro_sucesso`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.visualizar
      ? this.confirmarVoltar().then(() => {
          this.$router.push({ name: 'veiculo' });
        })
      : this.confirmarCancelar().then(() => {
          this.$router.push({ name: 'veiculo' });
        });
    },
  },
};
</script>
