<template lang="">
  <div>
    <v-form
      ref="form"
      class="row mt-2"
    >
      <input-text
        v-model="form.placa"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.placa')"
        obrigatorio
        trim
        :max="8"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.fabricante"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.fabricante')"
        obrigatorio
        trim
        :max="50"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.modelo"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.modelo')"
        obrigatorio
        trim
        :max="50"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.cor"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.cor')"
        obrigatorio
        trim
        :max="20"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.anoFabricacao"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.anoFabricacao')"
        obrigatorio
        :max="4"
        type="number"
        maxlength="4"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.anoModelo"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.anoModelo')"
        obrigatorio
        :max="4"
        type="number"
        maxlength="4"
        :disabled="visualizar"
      />
      <input-colaborador
        ref="inputColaborador"
        v-model="form.principalCondutorColaborador"
        class="col-12 col-md-4"
        :label="$t('modulos.veiculo.formulario.principalCondutorColaborador')"
        :placeholder="$t('geral.inputs.selecione')"
        :disabled="visualizar"
        :multiplos="false"
        obrigatorio
      />
      <input-text
        v-model="form.renavam"
        class="col-12 col-md-4"
        :label="$t('modulos.veiculo.formulario.renavam')"
        :max="11"
        :disabled="visualizar"
      />
      <input-date
        v-model="form.dataCompra"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.dataCompra')"
        :disabled="visualizar"
      />
      <input-money
        v-model="form.valorCompra"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.valorCompra')"
        :min="0"
        type="number"
        :disabled="visualizar"
      />
      <input-date
        v-model="form.dataVenda"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.dataVenda')"
        :disabled="visualizar"
      />
      <input-money
        v-model="form.valorVenda"
        class="col-12 col-md-2"
        :label="$t('modulos.veiculo.formulario.valorVenda')"
        :min="0"
        type="number"
        :disabled="visualizar"
      />
      <input-text
        v-model="form.observacao"
        class="col-12 col-md-8"
        :label="$t('modulos.veiculo.formulario.observacao')"
        :max="200"
        :disabled="visualizar"
      />
    </v-form>
  </div>
</template>
<script>
import { InputColaborador } from '@/components/inputs';
export default {
  components: { InputColaborador },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visualizar: false,
    };
  },
  mounted() {
    if (this.$route.name == 'veiculo-visualizar') this.visualizar = true; 
  },
};
</script>
