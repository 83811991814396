import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export class VeiculoModel {
  constructor({
    id,
    fabricante,
    modelo,
    placa,
    cor,
    principalCondutorColaborador,
    anoFabricacao,
    anoModelo,
    renavam,
    dataCompra,
    dataVenda,
    valorCompra,
    valorVenda,
    observacao = '',
  }) {
    this.id = id;
    this.fabricante = fabricante;
    this.modelo = modelo;
    this.placa = placa;
    this.cor = cor;
    if (principalCondutorColaborador)
      this.principalCondutorColaborador = new DropdownModel(
        principalCondutorColaborador
      );
    this.anoFabricacao = anoFabricacao;
    this.anoModelo = anoModelo;
    this.renavam = renavam;
    this.dataCompra = dataCompra;
    this.dataVenda = dataVenda;
    this.valorCompra = valorCompra;
    this.valorVenda = valorVenda;
    this.observacao = observacao;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.principalCondutorColaboradorId =
      this.principalCondutorColaborador?.value;
    return retorno;
  }
}
