<template lang="">
  <div>
    <tabela-padrao-prime-vue
      ref="tabela"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="false"
      :mostrar-seletor="false"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostra-header="false"
      ajustar-altura-linha
      @paginar="listarRegistros"
    >
      <template v-slot:chekListChegada="{ slotProps }">
        <span :class="obterBoleanoEnum(slotProps.data.chekListChegada)?.cor + '--text'">
          {{ obterBoleanoEnum(slotProps.data.chekListChegada)?.text }}
        </span>
      </template>
      <template v-slot:chekListSaida="{ slotProps }">
        <span :class="obterBoleanoEnum(slotProps.data.chekListSaida)?.cor + '--text'">
          {{ obterBoleanoEnum(slotProps.data.chekListSaida)?.text }}
        </span>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import VeiculoService from '@common/services/cadastros/VeiculoService';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'usuario.nome',
            text: this.$t('modulos.veiculo.historico.usuario'),
            sortable: true,
          },
          {
            value: 'dataSaida',
            text: this.$t('modulos.veiculo.historico.dataSaida'),
            sortable: true,
            formatter: v => v ? helpers.formatarDataBr(v, true) : v,
          },
          {
            value: 'kmSaida',
            text: this.$t('modulos.veiculo.historico.kmSaida'),
            sortable: true,
          },
          {
            value: 'chekListSaida',
            text: this.$t('modulos.veiculo.historico.checklistSaida'),
            sortable: true,
          },
          {
            value: 'dataChegada',
            text: this.$t('modulos.veiculo.historico.dataChegada'),
            sortable: true,
            formatter: v => v ? helpers.formatarDataBr(v, true) : v,
          },
          {
            value: 'kmChegada',
            text: this.$t('modulos.veiculo.historico.kmChegada'),
            sortable: true,
          },
          {
            value: 'chekListChegada',
            text: this.$t('modulos.veiculo.historico.checklistChegada'),
            sortable: true,
          },
          {
            value: 'destino',
            text: this.$t('modulos.veiculo.historico.destino'),
            sortable: true,
          },
          {
            value: 'observacao',
            text: this.$t('modulos.veiculo.historico.observacao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        porPagina: 10,
        paginaAtual: 1,
      },
      filtroAplicado: { filter: '' },
    };
  },
  mounted() {
    this.listarRegistros();
  },
  methods: {
    obterBoleanoEnum(v){
        return helpers.BoleanoEnum.find(el => el.value === !!v);
    },
    listarRegistros(paginaAtual = this.tabela.paginaAtual, porPagina = this.tabela.porPagina) {
      if(!this.id) return;
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      VeiculoService.listarHistoricos(this.id, parametros)
        .then((res) => {
            this.tabela.dados = res.data.items;
            this.tabela.quantidadeItens = res.data.totalItems;
            this.tabela.quantidadePaginas = res.data.totalPages;
            this.tabela.paginaAtual = paginaAtual;
            this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
